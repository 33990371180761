import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'

const Wrapper = styled.article`
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    span {
      display: none;
    }
  }
  ${BreakPoints.large} {
    margin-bottom: 10px;
  }
`

/**
 * 関連記事カードセット
 * TODO 川上申し送り事項：記事詳細下部に表示されるコンポーネントです。
 * ここでは、表示している記事に関連した他の記事3件が表示される予定です
 * @constructor
 */
const ColumnRelationCards0064 = () => (
  <Wrapper>
    <ColumnH1 label="関連コラム" />
    <section>
      {/* <ColumnBtnCard */}
      {/*   href="/column/details/C0066" */}
      {/*   imgId="c0066.jpg" */}
      {/*   label="ハイブリッドワークとは？メリットやデメリット、成功させるポイントについて解説！" */}
      {/*   date="2022.04.15" */}
      {/* /> */}
    </section>
  </Wrapper>
)

export default ColumnRelationCards0064
