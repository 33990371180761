import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0064 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="コロナ禍で変わった労働環境。有給取得率や残業は？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.07.05</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        コロナが本格化し出した2020年から3年の時を経て、労務管理手法については大きな変化が見られました。労務管理手法の変化は我が国の最も多くを占める被雇用者に対しても直接的に影響が及ぶ部分で被雇用者の対応に迫られる人事労務担当者においてもこの3年間は激動の3年間であったと言っても過言ではありません。今回はコロナ禍で変わった労務環境やその間の有給取得率や残業に注視し、解説します。
      </p>
      <img src="/images/column/details/c0064.jpg" alt="キンクラコラム画像64" />
      <h1 id="toc1">テレワークの導入による労働環境の変化</h1>
      <p>
        一部のエッセンシャルワーカーを除き、
        <span>
          政府の要請によって、ルールの整備が追い付いていない企業であってもテレワークの導入は避けられないもの
        </span>
        でした。求職者目線でも、エッセンシャルワーカーでないにも関わらず、テレワーク制度が整っていない企業となれば、懐疑的に見られてしまうとの声もあり、ルールの整備以前に早急に導入に踏み切る企業も多くありました。もちろん、根底には感染拡大防止があるものの、これまで当たり前のように目の前で業務遂行していた同僚等が「画面越しの人」となり、これらの労働者を管理する中間管理職以上はテレワーク時の「労働時間管理」に頭を悩ませていました。そこで、様々な機器等を駆使し、労働時間管理に奔走することとなりますが、行き過ぎた管理によって「プライバシーの侵害」や「逆に生産性が下がる」といった反論があり、再考を余儀なくされた企業も少なくありません。また、これらの反論は「思っていても立場上口には出せない」といった問題を抱えている労働者の存在も一定数いるものと考えられることから、氷山の一角であると言えます。
        <br />
        <br />
        他方、物理的に対面での労務提供の機会が少なくなった（緊急事態宣言解除後は全面テレワークから段階的に出社への切り替えも見られ始めた）ため、
        <span>ハラスメントの相談が減ったという思わぬ形での副次的効果</span>
        がありました。
        <br />
        <br />
        その他、テレワークでも業務が十分に成り立つことが社会全体で証明されたため、高額な家賃を支払いつつづける都市部のオフィスに対して経営層から懐疑的な意見が出てきたため、
        <span>
          オフィスの移転やコロナ禍後もテレワークを継続するといった動き
        </span>
        も見られました。
        <br />
        <br />
        テレワークについては労務管理ではなく、社会保険の見地から言及すると、「通勤手当」は一定額までは税法上非課税であるものの、社会保険上の「報酬」にあたるため、テレワークを通じて基本給は同じであっても（通勤手当がなくなるあるいは出社回数に応じた回数払いへ変更となったことが起因して）総支給額が下がることが多くなり、
        <span>標準報酬月額が下がるといった動きに直結</span>
        しました。これは短期的な視点に立つと、月々の社会保険料額の負担が少なくなる（会社負担分だけでなく従業員負担分も）ため、メリットにも見えますが、原則として65歳から受給開始となる厚生年金から支給される老齢厚生年金が低額になるというデメリットも孕んでいます。
        <br />
        <br />
        よって、「労務環境」に注視した総括とすると、
        <span>
          テレワーク導入を契機に多様な労務管理手法が現実的に運用可能であることを（自社だけでなく多くの会社で）実証できた点
        </span>
        が挙げられます。また、ハラスメントは減少したように見えるものの、テレワークならではのハラスメント（例えばテレワーク時におけるWeb会議での仮想背景の制限や常時カメラオン設定）も出てきたことから、ハラスメントをゼロにするには相当な企業努力が必要であることも浮き彫りとなりました。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc2">有給取得率</h1>
      <p>
        テレワークが増えたことにより、言葉を選ばずに申し上げるとある程度、自由度のある働き方となったため、
        <span>
          有給休暇の取得率が逆に下がってしまったという問題に直面した企業もあります。
        </span>
        ただし、2019年4月1日以降は年10日以上の有給休暇が付与される労働者には付与された日から1年の間に5日の取得義務があります。これはテレワークであっても例外ではありませんので、注意が必要です。他方、コロナ禍以降、「終身雇用」の確約が社会的にも難しいという情勢になり、副業兼業を認める企業が増えてきました。そこで、副業兼業にチャレンジする労働者が増えたことで、副業兼業者に対する労務管理（例えば雇用契約であれば週に1日程度のアルバイトであっても有給休暇は発生することや、雇用保険は65歳未満の場合主たる賃金を受ける企業のみでしか加入できない）の理解が浸透したことが挙げられます。
      </p>

      <h1 id="toc3">残業</h1>
      <p>
        コロナ禍により、多くの企業で売上減となり、各種助成金を活用し、雇用を守る動きが見られました。そこで、通常の賃金よりも高額となる残業代について、必要以上に残業を行うことは企業経営を圧迫することにつながるため、
        <span>残業については使用者への承認制へと変更</span>
        し、免疫力低下の引き金ともなる長時間労働の防止はコロナ感染の防止にも寄与するものとして、多くの企業で採用され始めました。それだけでなく、残業の承認制は必要以上の残業を抑制し、ひいては事業場ごとに締結する36協定の遵守にも繋がったと考えられます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0064
