import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import Conclusion001 from '../../../components/molecules/Conclusion001'
import Conclusion002 from '../../../components/molecules/Conclusion002'
import Conclusion003 from '../../../components/molecules/Conclusion003'
import Conclusion004 from '../../../components/molecules/Conclusion004'
import Conclusion005 from '../../../components/molecules/Conclusion005'
import Conclusion006 from '../../../components/molecules/Conclusion006'
import Conclusion007 from '../../../components/molecules/Conclusion007'
import Conclusion008 from '../../../components/molecules/Conclusion008'
import Conclusion009 from '../../../components/molecules/Conclusion009'
import CarouselBanner from '../../../components/molecules/carouselBanner'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0064 from '../../../components/molecules/columnDetailInner0064'
import ColumnRelationCards0064 from '../../../components/molecules/columnRelationCards0064'
import PopupBanner from '../../../components/molecules/popupBanner'
import UsefulFunctionLayout006 from '../../../components/molecules/usefulFunctionLayout006'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0064 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: '/column' },
    { title: 'コラム記事一覧', link: '/column/list' },
    {
      title: 'コロナ禍で変わった労働環境。有給取得率や残業は？',
    },
  ]

  // 0~8ランダム数字を保存
  const [no, setNo] = useState(-1)
  useEffect(() => {
    setNo(Math.floor(Math.random() * 9))
  }, [])

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="コロナ禍で変わった労働環境。有給取得率や残業は？"
        description="コロナ禍で変わったテレワークの導入による労働環境の変化やその間の有給取得率や残業に注視し、解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0064/"
        ogType="article"
        ogTitle="コロナ禍で変わった労働環境。有給取得率や残業は？"
        ogDescription="コロナ禍で変わったテレワークの導入による労働環境の変化やその間の有給取得率や残業に注視し、解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0064.jpg"
      />
      <ColumnDetailsBody>
        {/** ポップアップバナー */}
        <PopupBanner />
        {/** 記事本体 */}
        <ColumnDetailInner0064 no={no} />

        {/** ここに9パターンの広告設定する */}
        {no === 0 && <Conclusion001 />}
        {no === 1 && <Conclusion002 />}
        {no === 2 && <Conclusion003 />}
        {no === 3 && <Conclusion004 />}
        {no === 4 && <Conclusion005 />}
        {no === 5 && <Conclusion006 />}
        {no === 6 && <Conclusion007 />}
        {no === 7 && <Conclusion008 />}
        {no === 8 && <Conclusion009 />}

        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout006 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** カルーセルバナー */}
        <CarouselBanner />
        {/** 関連記事 */}
        <ColumnRelationCards0064 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0064

export const pageQuery = graphql`
  query C0064 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
